import { Bar, mixins } from "vue-chartjs"

const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted () {
    if (this.chartData) {
      this.rerenderChart(this.chartData)
    }
  },
  methods: {
    rerenderChart (val) {
      try {
        const options = this.chartOptions ? this.chartOptions : this.options
        this.renderChart(val, options)
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    chartData (val) {
      this.rerenderChart(val)
    }
  }
}
